
import DashboardService from '../services/dashboard-service'
import DispatchService from '../../dispatch/services/dispatch-service'
import {
  Component,
  Vue
} from 'vue-property-decorator'

  @Component({
    name: 'JobTimeline'
  })
export default class JobTimeline extends Vue {
  protected isBusy = false
    protected dispatchNotes: any = []
    private items: any = []
    protected locations: any = ['Puttalam Yard']
    public job: any = {}
    protected lines: any = []
    protected line: any = {}
    public modes: any = []
    public vehicles: any = []
    public approvals: any = {}
    public loading: any = []
    public unloading: any = []
    public bowsers: any = []
    public letter = false
    public isApproval = false
    protected dispatchedQuantity = 0
    protected fields: any = [{
      key: 'bowser',
      label: 'Bowser',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'wheels',
      label: 'Wheels',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'capacity',
      label: 'Capacity',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'commence_loading',
      label: 'Commence Loading',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'completed_loading',
      label: 'Completed Loading',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'content_type',
      label: 'Content Type',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'tonnage',
      label: 'Tonnage',
      thStyle: {
        width: '10%'
      }
    }
    ]

    mounted () {
      this.items = this.items.map(items => ({
        ...items
      }))
    }

    public showModal (job: any) {
      this.job = job
      this.populateJobLines();
      // this.populateDispatchNotes(job.id);
      (this.$refs.JobTimelineModal as any).show()
    }

    public hideModal () {
      this.job = {}
      this.line = {}
      this.lines = []
      this.modes = []
      this.vehicles = []
      this.approvals = {}
      this.bowsers = []
      this.loading = []
      this.unloading = []
      this.letter = false
      this.dispatchNotes = []
      this.dispatchedQuantity = 0
      this.isApproval = false;
      (this.$refs.JobTimelineModal as any).hide()
    }

    protected populateJobLines () {
      this.job.lines.forEach((line: any) => {
        this.lines.push({
          value: {
            id: line.id,
            job_id: line.job_id,
            type: line.service.type,
            number: line.job_line_number,
            est_volume: line.estimated_volume,
            act_volume: line.actual_volume,
            uom: line.unit_of_measurement,
            receivable_rate: line.receivable_rate,
            payable_client_rate: line.payable_client_rate,
            payable_agent_rate: line.payable_agent_rate,
            payable_staff_rate: line.payable_staff_rate,
            currency: line.currency
          },
          text: line.job_line_number
        })
      })

      if (this.job.lines.length < 2) {
        this.job.lines.forEach((line: any) => {
          this.line = {
            id: line.id,
            job_id: line.job_id,
            type: line.service.type,
            number: line.job_line_number,
            est_volume: line.estimated_volume,
            act_volume: line.actual_volume,
            uom: line.unit_of_measurement,
            receivable_rate: line.receivable_rate,
            payable_client_rate: line.payable_client_rate,
            payable_agent_rate: line.payable_agent_rate,
            payable_staff_rate: line.payable_staff_rate,
            currency: line.currency
          }
        })
      }

      this.populateJobModeData(this.line)
    }

    protected populateJobModeData (value: any) {
      if (Object.keys(value).length === 0) return false
      DashboardService.getJobMode(value.job_id, value.id).then((response) => {
        if (response.data.approvals !== null) this.isApproval = true
        this.modes = response.data.modes
        this.vehicles = response.data.vehicles
        this.approvals = response.data.approvals
        this.bowsers = response.data.bowsers
        this.loading = response.data.loading
        this.unloading = response.data.unloading
        this.letter = response.data.letter
      })
    }

  // protected populateDispatchNotes (jobId: any) {
  //   this.isBusy = true
  //   DispatchService.getDispatchNotesofJob(jobId).then((response) => {
  //     this.isBusy = false
  //     this.dispatchNotes = response.data
  //     this.dispatchNotes.forEach(item => {
  //       this.dispatchedQuantity += parseInt(item.tonnage)
  //     })
  //   })
  // }
}

