
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DashboardService from '../services/dashboard-service'
import JobTimelineModal from './JobTimeline.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import Months from '@/data/months.json'
import {
  Chart,
  registerables
} from 'chart.js'
import Status from '@/components/Status/index.vue'
Chart.register(...registerables)
  @Component({
    name: 'Dashboard',
    components: {
      JobTimelineModal,
      Status
    }
  })

export default class Dashboard extends Vue {
    protected months = Months
    protected width = 200
    protected height = 200

    protected bargeList: any = []

    protected pendingJobs: any = []
    protected commencedJobs: any = []
    protected operationalJobs: any = []
    protected completedJobs: any = []

    protected pendingJobQuery: any = null
    protected commencedJobQuery: any = null
    protected operationalJobQuery: any = null
    protected completedJobQuery: any = null

    created () {
      this.boot()
    }

    mounted () {
      this.populateMonthlyJobs()
    }

    get filteredPendingJobs () {
      const query = this.pendingJobQuery
      if (query === '' || query === null) return this.pendingJobs

      return this.pendingJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get filteredCommencedJobs () {
      const query = this.commencedJobQuery
      if (query === '' || query === null) return this.commencedJobs

      return this.commencedJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get filteredOperationalJobs () {
      const query = this.operationalJobQuery
      if (query === '' || query === null) return this.operationalJobs

      return this.operationalJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get filteredCompletedJobs () {
      const query = this.completedJobQuery
      if (query === '' || query === null) return this.completedJobs

      return this.completedJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateNewJobs()
      this.populateCommencedJobs()
      this.populateOperationalJobs()
      this.populateCompletedJobs()
      this.populateBargeRobs()
    }

    protected async populateNewJobs () {
      const reponse = await DashboardService.getNewJobs()
      this.pendingJobs = reponse.data
    }

    protected async populateCommencedJobs () {
      const reponse = await DashboardService.getCommencedJobs()
      this.commencedJobs = reponse.data
    }

    protected async populateOperationalJobs () {
      const reponse = await DashboardService.getAttentiveJobs()
      this.operationalJobs = reponse.data
    }

    protected async populateCompletedJobs () {
      const reponse = await DashboardService.getCompletedJobs()
      this.completedJobs = reponse.data
    }

    protected async populateBargeRobs () {
      const response = await DashboardService.getBargeRobs()
      this.bargeList = response.data
    }

    protected populateMonthlyJobs () {
      DashboardService.getMonthlyJobs().then(response => {
        return new Chart((this.$refs.MonthlyJobVolume as any), {
          type: 'bar',
          data: {
            datasets: [{
              label: 'No.of Jobs',
              backgroundColor: '#00abfa',
              borderColor: '#03589e',
              data: this.sortByMonthly(response.data)
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              y: {
                min: 0,
                max: 50,
                beginAtZero: true
              }
            },
            parsing: {
              xAxisKey: 'month',
              yAxisKey: 'jobs'
            }
          }
        })
      })
    }

    protected sortByMonthly (data: any) {
      const months = this.months.map((month) => { return month.text })
      const lastMonthIndex = months.indexOf(data[data.length - 1].month)

      return Array.from({ length: Math.max(12 - data.length, 0) }, (v, i) => (lastMonthIndex + i + 1) % 12)
        .map((monthindex) => ({ month: months[monthindex], jobs: 0 }))
        .concat(data).sort((a, b) => {
          return months.indexOf(a.month) - months.indexOf(b.month)
        })
    }

    public openTimeLineModal (job: any) {
      (this.$refs.timelineModal as any).showModal(job)
    }
}

