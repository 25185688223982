import APIService from '@/services/api-service'
const resource = '/dashboard'

class DashboardService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getNewJobs () {
    return this.connector.get(`${resource}/new-jobs`)
  }

  public getCommencedJobs () {
    return this.connector.get(`${resource}/commenced-jobs`)
  }

  public getAttentiveJobs () {
    return this.connector.get(`${resource}/attentive-jobs`)
  }

  public getCompletedJobs () {
    return this.connector.get(`${resource}/completed-jobs`)
  }

  public getMonthlyJobs () {
    return this.connector.get(`${resource}/monthly-jobs`)
  }

  public getJobMode (jobId: any, jobLineId: any) {
    return this.connector.get(`${resource}/mode/${jobId}/${jobLineId}`)
  }

  public getBargeRobs () {
    return this.connector.get(`${resource}/barge-robs`)
  }
}

export default new DashboardService()
